import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import AppIndex from '../../src/index'; 

TopLayout.propTypes = {
  children: PropTypes.node,
};

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charset="utf-8" />

        {/* Favicon */}
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />

        <meta name="theme-color" content="#000000" />
        <link rel="manifest" href="manifest.json" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

        {/* Google Font */}   
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600;700&display=swap" rel="stylesheet" />
        <link  href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap" rel="stylesheet" />
        
      </Helmet>
      <AppIndex>
        {props.children}
      </AppIndex>
    </React.Fragment>
  );
}
