import * as React from 'react'
import PropTypes from 'prop-types';
// material
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g>
  </svg> 


      {/*
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="height: 512px; width: 512px;">
        <path d="M0 0h512v512H0z" fill="#ffffff" fill-opacity="1"></path>
        <g class="" transform="translate(0,0)" style="">
          <path d="M145.896 18.31l-21.51 3.016 50.184 404.992c7.915-.592 16-1.099 24.248-1.515l-30.935-237.614c25.61-7.714 50.943-16.448 73.25-36.277 14.77 90.454-1.795 182.196-2.377 272.658 12.68-.146 26.55-.009 37.9.293-.566-6.92-7.696-101.44 24.094-130.373 18.74-17.055 75.734-6.549 75.734-6.549 13.115 21.985 13.064 36.927 8.98 62.612l31.163 8.045c2.469-14.451 1.707-51.72-7.055-96.522-1.229-2.948-10.45-4.763-18.036-8.04-.183-30.68 6.268-60.047.273-90.727-14.382-11.332-45.991-20.998-70.762-34.35l-5.58-.762c-7.236 7.58-16.643 12.56-27.188 12.56-15.213-2.301-26.7-10.05-33.625-20.849l-12.734-1.736c-23.748 18.203-50.06 31.28-77.733 41.635zM288.28 36.94c-6.21 0-12.37 3.612-17.55 11.154-5.181 7.542-8.762 18.73-8.762 31.256 0 12.525 3.58 23.711 8.762 31.254 5.18 7.542 11.34 11.154 17.55 11.154 6.211 0 12.372-3.612 17.553-11.154 5.18-7.543 8.76-18.729 8.76-31.254 0-12.526-3.58-23.714-8.76-31.256-5.18-7.542-11.342-11.155-17.553-11.155zM109.873 54.4c-.932 2.868-1.81 5.768-2.848 8.555-3.482 9.362-7.548 18.137-12.91 25.352-5.361 7.214-12.501 13.287-21.787 14.603-13.724 1.946-21 6.09-25.857 11.809-4.858 5.72-7.681 14.024-9.352 25.42-2.552 17.412-.925 39.067 1.97 61.132 1.418-4.107 2.921-8.171 4.733-12.01 4.205-8.905 9.672-17.033 17.334-23.017 7.663-5.984 17.6-9.48 28.815-9.146 7.657.228 13.652-4.943 18.765-14.57 5.056-9.52 7.849-22.506 7.909-31.97zM323.744 160.97c13.772 8.366 27.424 15.481 44.86 16.52 6.827 23.86 1.635 49.908.83 73.769-22.109-4.356-43.114-9.48-45.623-23.147 2.972-34.461 3.447-49.16-.067-67.142zm48.836 203.847c-4.585 21.75-13.021 42.473-24.904 63.268 36.88 2.608 75.117 5.883 112.222 15.611-.06-19.97-6.113-40.476-16.906-60.699zm-127.242 76.692c-97.482.507-174.844 14.648-227.338 28.416V491h476v-18.94c-58.673-14.917-146.115-31.086-248.662-30.552z" fill="#00ab55" fill-opacity="1"></path>
        </g>
        </svg>
      */}

            
    
    </Box>
  );
}
