import * as React from 'react';
// mock api
import './_apis_';

// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// lightbox
import 'react-image-lightbox/style.css';

// editor
import 'react-quill/dist/quill.snow.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

//import ReactDOM from 'react-dom';
import { LocationProvider /*BrowserRouter*/ } from '@reach/router'; //from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// material
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// redux
import { store, persistor } from './redux/store';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
// components
import LoadingScreen from './components/LoadingScreen';

//import { AuthProvider } from './contexts/JWTContext';
// import { AuthProvider } from './contexts/FirebaseContext';
// import { AuthProvider } from './contexts/AwsCognitoContext';
import { AuthProvider } from './contexts/Auth0Context';

//
import App from './App';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

export default function AppIndex(props) 
{
    return (
        <HelmetProvider>
             <ReduxProvider store={store}>
                <PersistGate loading={<LoadingScreen />} persistor={persistor}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SettingsProvider>
                    <CollapseDrawerProvider>
                        {/*<BrowserRouter>*/}
                        <LocationProvider> {/* Required to use useLocation() */}
                            <AuthProvider>
                                <App>
                                    {props.children}
                                </App>
                            </AuthProvider>
                        </LocationProvider>
                        {/*</BrowserRouter>*/}
                    </CollapseDrawerProvider>
                    </SettingsProvider>
                </LocalizationProvider>
                </PersistGate>
            </ReduxProvider>
        </HelmetProvider>
    );
}