import * as React from 'react'
import PropTypes from 'prop-types';

import { auth0Config } from '../config';

import { Auth0Provider } from '@auth0/auth0-react';

import { navigate } from 'gatsby';

AuthProvider.propTypes = {
    children: PropTypes.node
  };

function AuthProvider({ children }) {

    // https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#2-protecting-a-route-in-a-gatsby-app
    const onRedirectCallback = (appState) => {
        // Use Gatsby's navigate method to replace the url
        navigate(appState?.returnTo || '/user', { replace: true });
      };

    return (
        <Auth0Provider
            domain={auth0Config.domain}
            clientId={auth0Config.clientId}
            redirectUri={/*auth0Config.callback*/  window.location.origin}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
  }
  
  export { AuthProvider };