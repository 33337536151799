import { useContext } from 'react';
//import { AuthContext } from '../contexts/JWTContext';
// import { AuthContext } from '../contexts/FirebaseContext';
// import { AuthContext } from '../contexts/AwsCognitoContext';
//import { AuthContext } from '../contexts/Auth0Context';
import { useAuth0 } from '@auth0/auth0-react';

// ----------------------------------------------------------------------

const useAuth = () => useAuth0();// useContext(AuthContext);

export default useAuth;
